/*
 * @Date: 2023-04-10 12:58:17
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-04 14:03:59
 * @FilePath: \P1-M1-QRP-CODE\config\Host.config.js
 */
let Config = {};

export const DomainURL       = 'https://qrpm1stag.betway178.biz';

export const STAGE_CMS_URL   = 'https://cmsapistag.betway178.biz'; // stage
//export const STAGE_CMS_URL   = 'https://cache.viptoss.com'; // stage
export const SL_CMS_URL      = 'https://cmsapisl.betway178.biz';   // SL
// export const LIVE_CMS_URL    = 'https://cmsapi.betway178.biz';     // LIVE
export const LIVE_CMS_URL    = 'https://cache.bw873ty.com'; // LIVE
export const Live_BAK_CMS_URL = 'https://cache.36bw87.com'; // BAK


export const FP_BK_URL      = 'https://shsbwkpfcn.funpo.com:2041/thirdparty-payment';

export const SL_HOST_URL_API       = 'https://p1-qrp-sl-awstk.fubnb.com'; //SL
export const STAGE_HOST_URL_API = 'https://p1-qrp-stage-awstk.fubnb.com'; //stage
export const LIVE_HOST_URL_API  = 'https://p1-qrp-live-awstk.fubnb.com'; // LIVE


export const STAGE_ENPOINT_URL = 'https://p1-qrp-stage-awstk.fubnb.com'; //stage
export const ENPOINT_URL = 'https://p1-qrp-live-awstk.fubnb.com'; //live
// 判断环境
if ( typeof window !== 'undefined' ){
	let parsed = Domainparse(window.location.host);
	let STRAPI_CMS_URL = (parsed?.domain) ? `https://cache.${parsed.domain}` : LIVE_CMS_URL;
	let LocalHost = global.location.host;
	let isHttps = 'https:' === document.location.protocol;
	let isSl = Boolean(
		[
			'qrpm1sl',
		].find((v)=>global.location.href.includes(v))
	)

	const isLocal = global.location.href.includes('localhost:8889');
	const isStage = global.location.toString().includes("qrpm1stag");
	if (isLocal) { //本地環境
		Config = {
			HostApi   : STAGE_HOST_URL_API,
			LocalHost : '',
			CMSURL    : STAGE_CMS_URL,
			ENDPOINT  : STAGE_ENPOINT_URL,
			QRHost	  : FP_BK_URL
		}
	} else if (isStage) { //stage環境
		STRAPI_CMS_URL = `https://cache.${LocalHost}`;
		Config = {
			HostApi   : STAGE_HOST_URL_API,
			LocalHost : '',
			CMSURL    : STRAPI_CMS_URL,
			ENDPOINT  : STAGE_ENPOINT_URL,
			QRHost	  : FP_BK_URL
		}
	} else if (isSl) {
		STRAPI_CMS_URL = `https://cache.${LocalHost}`;
		Config = {
			HostApi   : SL_HOST_URL_API,
			LocalHost : '',
			CMSURL    : STRAPI_CMS_URL,
			ENDPOINT  : STAGE_ENPOINT_URL,
			QRHost	  : FP_BK_URL
		}
	} else {
		// 判断是否在SL
		Config = {
			/* 灰度地址 */
			HostApi: LIVE_HOST_URL_API,
			LocalHost: (isHttps ? 'https://' : 'http://') + LocalHost + '/',
			CMSURL: STRAPI_CMS_URL,
			ENDPOINT  : ENPOINT_URL,
			QRHost	  : FP_BK_URL
		};

	}


}

export default { Config };

	/**
	 * @description: 获取二级域名 转换动态api 域名
	 * @param {*} input 完整域名
	 * @return {*}
	 */
	function Domainparse(input) {
		if (typeof input !== "string") {
			throw new TypeError("Domain name must be a string.");
		}
		// Force domain to lowercase.
		var domain = input.slice(0).toLowerCase();
		// Handle FQDN.
		// TODO: Simply remove trailing dot?
		if (domain.charAt(domain.length - 1) === ".") {
			domain = domain.slice(0, domain.length - 1);
		}
		var parsed = {
			input: input,
			tld: null,
			sld: null,
			domain: null,
			subdomain: null,
			listed: false,
		};
		var domainParts = domain.split(".");
		// Non-Internet TLD
		if (domainParts[domainParts.length - 1] === "local") {
			return parsed;
		}
		var handlePunycode = function () {
			if (!/xn--/.test(domain)) {
				return parsed;
			}
			if (parsed.domain) {
				parsed.domain = Punycode.toASCII(parsed.domain);
			}
			if (parsed.subdomain) {
				parsed.subdomain = Punycode.toASCII(parsed.subdomain);
			}
			return parsed;
		};
		var rule = null;
		// Unlisted tld.
		if (!rule) {
			if (domainParts.length < 2) {
				return parsed;
			}
			parsed.tld = domainParts.pop();
			parsed.sld = domainParts.pop();
			parsed.domain = [parsed.sld, parsed.tld].join(".");
			if (domainParts.length) {
				parsed.subdomain = domainParts.pop();
			}
			return handlePunycode();
		}
		// At this point we know the public suffix is listed.
		parsed.listed = true;

		var tldParts = rule.suffix.split(".");
		var privateParts = domainParts.slice(
			0,
			domainParts.length - tldParts.length
		);

		if (rule.exception) {
			privateParts.push(tldParts.shift());
		}
		parsed.tld = tldParts.join(".");
		if (!privateParts.length) {
			return handlePunycode();
		}
		if (rule.wildcard) {
			tldParts.unshift(privateParts.pop());
			parsed.tld = tldParts.join(".");
		}

		if (!privateParts.length) {
			return handlePunycode();
		}
		parsed.sld = privateParts.pop();
		parsed.domain = [parsed.sld, parsed.tld].join(".");
		if (privateParts.length) {
			parsed.subdomain = privateParts.join(".");
		}
		return handlePunycode();
	}